import React from 'react';

const WavySeparator = () => (
    <svg
        className="w-full h-16 bg-beige-woody"
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
        fill="rgb(215, 200, 178)"
    >
        <path
            d="M0,128L30,160C60,192,120,256,180,245.3C240,235,300,149,360,117.3C420,85,480,107,540,138.7C600,171,660,213,720,213.3C780,213,840,171,900,160C960,149,1020,171,1080,181.3C1140,192,1200,192,1260,170.7C1320,149,1380,107,1410,85.3L1440,64L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320H0Z"
        />
    </svg>
);

export default WavySeparator;