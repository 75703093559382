import React, {useRef} from 'react';
import {motion, useInView} from 'framer-motion';
import {FaMapMarkerAlt} from 'react-icons/fa';

const Info = () => {
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    return (
        <div className="bg-beige-woody p-8 font-playpen" id="info-section">
            <div
                ref={ref}
                className="max-w-2xl lg:max-w-7xl mx-auto"
            >
                <motion.div
                    initial={{x: '-100%', opacity: 0}}
                    animate={isInView ? {x: 0, opacity: 1} : {}}
                    transition={{duration: 1}}
                    className="flex flex-col md:flex-row items-start gap-20"
                >
                    <div className="w-full md:w-1/2" id="info">
                        <h1 className="text-3xl font-bold text-gray-800 text-center mb-6">Atrašanās Vieta</h1>
                        <div className="mb-6 flex items-center bg-white rounded-lg p-4 shadow-md">
                            <FaMapMarkerAlt className="text-2xl mr-3 text-gray-700"/>
                            <p className="text-lg text-gray-800">
                                Ata Kronvalda iela, Baltā iela 2B, Cēsis, LV-4101
                            </p>
                        </div>
                        {/* Google Map Section */}
                        <div className="mb-6 rounded-lg overflow-hidden">
                            <iframe
                                title="Google Map Location"
                                className="w-full h-72"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2943.162029227197!2d24.50994221533342!3d57.313675081362576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e51a5c167aa3e7%3A0x247589f3aa3b1917!2sAta%20Kronvalda%20iela%2C%20Balt%C4%81%20iela%202B%2C%20C%C4%93sis%2C%20LV-4101!5e0!3m2!1sen!2slv!4v1697558381542!5m2!1sen!2slv"
                                allowFullScreen
                                loading="lazy"
                            ></iframe>
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 text-left">
                        <h2 className="text-3xl font-bold text-gray-800 text-center mb-6">Darba Laiki</h2>
                        <div className="bg-white p-4 rounded-lg shadow-md">
                            <ul className="pl-5 text-gray-800 flex flex-col gap-2">
                                <li>Pirmdiena: 12:00 – 20:30</li>
                                <li>Otrdiena: 12:00 – 20:30</li>
                                <li>Trešdiena: 12:00 – 20:30</li>
                                <li>Ceturtdiena: 12:00 – 20:30</li>
                                <li>Piektdiena: 12:00 – 21:00</li>
                                <li>Sestdiena: 11:30 – 21:00</li>
                                <li>Svētdiena: 12:00 – 20:00</li>
                            </ul>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default Info;