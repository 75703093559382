// src/components/Testimonials.js

import React, {useRef} from 'react';
import {motion, useInView} from 'framer-motion';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination, Autoplay} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {FaStar, FaUserCircle} from 'react-icons/fa';
import testimonials from './items/Reviews';

const Testimonials = () => {
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    return (
        <section className="py-32 bg-light-beige-woody font-playpen">
            <div
                ref={ref}
                className={`max-w-2xl lg:max-w-7xl mx-auto transform transition-transform duration-1500 ${isInView ? 'translate-x-0' : '-translate-x-full'}`}
            >
                <h2 className="text-3xl font-bold text-gray-900 text-center">Klientu Atsauksmes</h2>
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={30}
                    speed={2000}
                    slidesPerView={1}
                    pagination={{
                        clickable: true,
                        bulletClass: 'swiper-pagination-bullet custom-pagination-bullet',
                        bulletActiveClass: 'swiper-pagination-bullet-active custom-pagination-bullet-active',
                    }}
                    autoplay={{delay: 5000, disableOnInteraction: false}}
                    loop={true}
                    className="swiper-full mt-8"
                >
                    {testimonials.map((testimonial, index) => (
                        <SwiperSlide key={index} className="px-8 md:px-0 lg:px-8 xl:px-0">
                            <motion.div
                                initial={{opacity: 0, translateY: 20}}
                                animate={isInView ? {opacity: 1, translateY: 0} : {}}
                                transition={{duration: 1}}
                                className="text-center p-6 lg:p-12 bg-gray-100 rounded-lg shadow-lg h-96 md:h-72"
                            >
                                <div className="relative mb-4">
                                    <FaUserCircle className="w-8 h-8 md:w-16 md:h-16 text-beige-woody mx-auto mb-4"/>
                                </div>
                                <p className="text-sm md:text-base lg:text-lg text-gray-600">"{testimonial.feedback}"</p>
                                <h3 className="mt-4 text-xl font-semibold text-custom-gray">{testimonial.name}</h3>
                                <div className="flex justify-center mt-2">
                                    {[...Array(5)].map((_, starIndex) => (
                                        <FaStar
                                            key={starIndex}
                                            className={`text-yellow-500 ${starIndex < testimonial.stars ? 'filled' : ''}`}
                                        />
                                    ))}
                                </div>
                            </motion.div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
};

export default Testimonials;

