// src/components/ServiceItem.js
import React from 'react';

const ServiceItem = ({imageSrc, title, text}) => {
    return (
        <div className="flex flex-col items-center font-playpen">
            <img src={imageSrc} alt={title} className="w-48 h-48 md:w-54 md:h-54 xl:w-64 xl:h-64"/>
            <h3 className="text-2xl md:text-3xl text-custom-gray font-playpen text-center">{title}</h3>
            <p className="text-lg md:text-xl text-custom-gray font-playpen text-center px-10 md:px-0">{text}</p>
        </div>
    );
};

export default ServiceItem;
