// src/components/Footer.js
import React from 'react';
import {FaEnvelope, FaPhone, FaFacebook} from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="bg-light-beige-woody py-8 font-playpen">
            <div className="max-w-2xl md:max-w-7xl mx-auto text-center text-custom-gray">
                <p className="mb-2 text-xl">Sazinies ar mums:</p>
                <div
                    className="flex flex-col md:flex-row justify-center items-center md:items-start space-x-6 mt-4 gap-4 md:gap-0">
                    <div className="flex flex-col md:flex-row gap-2 items-center">
                        <a
                            href="mailto:glendeloka@inbox.lv"
                            className="flex items-center hover:text-gray-600 transition duration-300 ease-in-out"
                        >
                            <FaEnvelope className="mr-2"/>
                            glendeloka@inbox.lv
                        </a>
                        <a
                            href="tel:+37164161016"
                            className="flex items-center hover:text-gray-600 transition duration-300 ease-in-out"
                        >
                            <FaPhone className="mr-2"/>
                            +37164161016
                        </a>
                    </div>
                    <a
                        href="https://www.facebook.com/Glendeloka/?locale=lv_LV"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="hover:text-gray-600 transition duration-300 ease-in-out"
                    >
                        <FaFacebook className="text-xl"/>
                    </a>
                </div>
                <p className="mt-4">© 2024 Glendeloka. All Rights Reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;

