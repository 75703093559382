import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay} from 'swiper/modules';
import 'swiper/css';

const Hero = () => {
    return (
        <section
            id="herosection"
            className="relative bg-cover bg-center h-screen flex items-center justify-center font-playpen"
            style={{backgroundImage: 'url(/assets/Glendeloka4.jpg)'}}
        >
            <div className="absolute inset-0 bg-black opacity-50"></div>

            {/* Swiper Slider */}
            <Swiper
                modules={[Autoplay]}
                autoplay={{delay: 3000, disableOnInteraction: false}}
                loop={true}
                speed={2500}
                className="w-full h-full"
            >
                {/* Swiper Slide 1 */}
                <SwiperSlide>
                    <div
                        className="bg-cover bg-center h-full relative"
                        style={{backgroundImage: 'url(/assets/glendeloka4.jpg)'}}
                    >
                        <div className="absolute inset-0 bg-black opacity-50"></div>
                    </div>
                </SwiperSlide>

                {/* Swiper Slide 2 */}
                <SwiperSlide>
                    <div
                        className="bg-cover bg-center h-full"
                        style={{backgroundImage: 'url(/assets/table.jpg)'}}
                    >
                        <div className="absolute inset-0 bg-black opacity-50"></div>
                    </div>
                </SwiperSlide>

                {/* Swiper Slide 3 */}
                <SwiperSlide>
                    <div
                        className="bg-cover bg-center h-full"
                        style={{backgroundImage: 'url(/assets/food1.jpg)'}}
                    >
                        <div className="absolute inset-0 bg-black opacity-50"></div>
                    </div>
                </SwiperSlide>
                {/* Swiper Slide 4 */}
                <SwiperSlide>
                    <div
                        className="bg-cover bg-center h-full"
                        style={{backgroundImage: 'url(/assets/table3.jpg)'}}
                    >
                        <div className="absolute inset-0 bg-black opacity-50"></div>
                    </div>
                </SwiperSlide>
                {/* Swiper Slide 5 */}
                <SwiperSlide>
                    <div
                        className="bg-cover bg-center h-full"
                        style={{backgroundImage: 'url(/assets/table4.jpg)'}}
                    >
                        <div className="absolute inset-0 bg-black opacity-50"></div>
                    </div>
                </SwiperSlide>
                {/* Swiper Slide 6 */}
                <SwiperSlide>
                    <div
                        className="bg-cover bg-center h-full"
                        style={{backgroundImage: 'url(/assets/outside.jpg)'}}
                    >
                        <div className="absolute inset-0 bg-black opacity-50"></div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    );
};

export default Hero;


