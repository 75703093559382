import React, {useRef} from 'react';
import {motion, useInView} from 'framer-motion';
import ServiceItem from './items/ServiceItem';

const services = [
    {title: 'UZZVANI', text: 'Nepacietīgi gaidam zvanu tieši no tevis!', imageSrc: '/assets/calling.png'},
    {title: 'PASŪTI', text: 'Negaidi pasūti jau tagad!', imageSrc: '/assets/menu.png'},
    {title: 'IZBAUDI', text: 'Izbaudiet garšīgi pagatavoto ēdienu', imageSrc: '/assets/food.png'},
];

const Services = () => {
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    return (
        <section
            id="services-section"
            className="py-32 bg-cover bg-center font-playpen"
            style={{backgroundImage: 'url(/assets/beige_wood.jpg)'}}
        >
            <div className="max-w-7xl mx-auto text-center">
                <motion.div
                    ref={ref}
                    initial={{x: '-100%', opacity: 0}} // Start off-screen and transparent
                    animate={isInView ? {x: 0, opacity: 1} : {}}
                    transition={{duration: 1}}
                    className="flex flex-col lg:flex-row items-center justify-center xl:gap-8"
                >
                    {services.map((service, index) => (
                        <React.Fragment key={index}>
                            <ServiceItem
                                text={service.text}
                                title={service.title}
                                imageSrc={service.imageSrc}
                            />
                            {index < services.length - 1 && (
                                <img
                                    src="/assets/arrow.png"
                                    alt="Arrow"
                                    className="w-32 h-32 xl:w-40 xl:h-40 pb-10 rotate-90 lg:rotate-0"
                                />
                            )}
                        </React.Fragment>
                    ))}
                </motion.div>
            </div>
        </section>
    );
};

export default Services;
