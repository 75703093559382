import React, {useRef} from 'react';
import {motion, useInView} from 'framer-motion';

const About = () => {
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    return (
        <section id="about" className="py-24 bg-beige-woody relative font-playpen">
            <motion.div
                ref={ref}
                initial={{x: '-100%', opacity: 0}}
                animate={isInView ? {x: 0, opacity: 1} : {}}
                transition={{duration: 1}}
                className="max-w-2xl lg:max-w-7xl mx-auto flex flex-col md:flex-row items-center"
            >
                <div className="md:w-1/2 text-center md:text-left mx-4 md:pr-8 relative">
                    <img
                        src="/assets/plate_knife.png"
                        alt="Glendeloka"
                        className="w-32 h-32 md:w-40 md:h-40 xl:w-64 xl:h-64 absolute -rotate-45 left-2 -top-28 lg:-top-32 md:left-40 xl:-left-64"
                    />
                    <div className="mt-8 text-base lg:text-lg text-gray-600 p-2">
                        <h3 className="text-2xl font-bold text-gray-800">Par Glendeloku</h3>
                        <p className="mt-4">
                            Krodziņš ir ģimenes uzņēmums un mājīga atpūtas vieta, kur var baudīt gardus ēdienus. Mūsu
                            plašās telpas var uzņemt līdz pat 75 cilvēkiem ziemā, bet vasarā darbojas arī āra terase,
                            kas ļauj paēdināt līdz pat 135 viesiem.
                        </p>
                        <p className="mt-4">
                            Mēs piedāvājam ne tikai ēdināšanu uz vietas, bet arī izbraukuma ēdināšanu, kas ir lieliska
                            iespēja pasākumu organizēšanai. Mūsu komanda ir apņēmības pilna nodrošināt lielisku pieredzi
                            katram viesim, piedāvājot gan izsmalcinātus ēdienus, gan siltu apkalpošanu.
                        </p>
                    </div>
                </div>

                <div className="w-4/5 md:w-2/5 lg:w-1/2 mt-8 md:mt-0">
                    <img
                        src="/assets/glendeloka2.jpg"
                        alt="Glendeloka"
                        className="rounded-full shadow-xl w-full max-w-md h-auto mx-auto"
                        style={{aspectRatio: '1/1'}}
                    />
                </div>
            </motion.div>
        </section>
    );
};

export default About;

