import React from 'react';

const WavySeparator = () => (
    <svg
        className="w-full h-16 bg-beige-woody"
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
    >
        <defs>
            <pattern id="wavePattern" patternUnits="userSpaceOnUse" width="1440" height="320">
                <image
                    href="/assets/beige_wood.jpg"
                    x="0"
                    y="0"
                    width="1440"
                    height="320"
                    preserveAspectRatio="xMidYMid slice"
                />
            </pattern>
        </defs>

        <path
            d="M0,192L30,160C60,128,120,64,180,74.7C240,85,300,171,360,203.7C420,236,480,213,540,181.3C600,149,660,107,720,106.7C780,106,840,149,900,160C960,171,1020,149,1080,138.7C1140,128,1200,128,1260,149.3C1320,171,1380,213,1410,226.7L1440,240L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0H0Z"
            fill="url(#wavePattern)"
        />
    </svg>
);

export default WavySeparator;

