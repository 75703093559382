// src/components/items/Reviews.js

const testimonials = [
    {
        name: 'Madara',
        feedback: 'Lieliska vieta kur paēst, plaša un daudzveidīga ēdienkarte, normāla izmēra porcijas par adekvātu cenu, jauna mājīga terase, plaša autostāvvieta.\n' +
            'Pietiekami plaša bērnu ēdienkarte, bērnu stūrītis iekšā un ārā ir slidkalniņi.\n',
        stars: 5,
    },
    {
        name: 'Sandriņa',
        feedback: 'Patīkama un mierīga atmosfēra. Gards ēdiens. Iespējami gan zivs, gan gaļas ēdieni (cūkgaļa, liellops), pastas ar vistu vai garnelēm, picas, zupas, salāti, deserti. Lielas porcijas. Pieejama arī bērnu ēdienkarte.',
        stars: 5,
    },
    {
        name: 'Dzintars',
        feedback: 'Laipna apkalpošana garšīgi ēdieni. Ierodoties ar suņuku, tam tiek piedāvāts padzerties ūdeni.',
        stars: 5,
    },
    {
        name: 'Kristiāna',
        feedback: 'Garšīgi, ātra un laipna apkalpošana. Vaarbūt pat nedaudz par ātru, jo nepaspējām apēst zupas un jau klāt bija pamatēdiens',
        stars: 5,
    },
    {
        name: 'Vita',
        feedback: 'Mājīga un jauka atmosfēra,meitenes atsaucīgas un pretimnākošas. Bērniem laukumiņš,liela stāvvieta kur novietot auto. Nav ko piebilst,skaisti!',
        stars: 5,
    },
];

export default testimonials;
