import React, { useState, useEffect } from 'react';
import { FaPhone, FaEnvelope, FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
    const [isNavVisible, setIsNavVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            setIsNavVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
            setPrevScrollPos(currentScrollPos);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: 'smooth',
            });
        }
        setIsMobileMenuOpen(false);
    };

    return (
        <header
            className={`fixed top-0 left-0 w-full bg-black bg-opacity-15 backdrop-blur-sm z-50 font-playpen transition-all duration-300 ease-in-out ${isNavVisible ? 'top-0' : '-top-20'}`}>
            <div className="max-w-6xl mx-auto flex justify-between items-center py-4">
                <nav className="hidden md:flex space-x-6">
                    <a href="#herosection" className="text-white relative group">
                        Sākumlapa
                        <span className="absolute left-1/2 bottom-0 w-full h-[1px] bg-white transition-all duration-1000 ease-in-out transform -translate-x-1/2 scale-x-0 group-hover:scale-x-100"></span>
                    </a>
                    <a href="#about" className="text-white relative group">
                        Par mums
                        <span className="absolute left-1/2 bottom-0 w-full h-[1px] bg-white transition-all duration-1000 ease-in-out transform -translate-x-1/2 scale-x-0 group-hover:scale-x-100"></span>
                    </a>
                </nav>

                <img src="/assets/logo4.png" alt="Logo" className="ml-5 md:ml-0 w-28 h-12" />

                <div className="hidden md:flex space-x-6">
                    <a href="#info" className="text-white relative group">
                        Kontakti
                        <span className="absolute left-1/2 bottom-0 w-full h-[1px] bg-white transition-all duration-1000 ease-in-out transform -translate-x-1/2 scale-x-0 group-hover:scale-x-100"></span>
                    </a>
                    <a href="tel:+37112345678" className="text-white relative group">
                        Zvanīt
                        <span className="absolute left-1/2 bottom-0 w-full h-[1px] bg-white transition-all duration-1000 ease-in-out transform -translate-x-1/2 scale-x-0 group-hover:scale-x-100"></span>
                    </a>
                </div>

                {/* Hamburger menu icon */}
                <div className="md:hidden">
                    {!isMobileMenuOpen && (
                        <FaBars className="text-2xl cursor-pointer text-white mr-2" onClick={() => setIsMobileMenuOpen(true)} />
                    )}
                </div>
            </div>

            {/* Mobile menu */}
            <nav className={`absolute top-0 left-0 w-full h-screen bg-black bg-opacity-90 flex flex-col justify-center items-center transform z-50 ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
                <FaTimes className="text-2xl cursor-pointer absolute top-5 right-5 text-white z-50" onClick={() => setIsMobileMenuOpen(false)} />
                <img src="/assets/logo4.png" alt="Logo" className="w-48 h-24 absolute top-32" loading="lazy" />
                <button onClick={() => scrollToSection('herosection')} className="text-white my-4 text-2xl hover:underline hover:underline-offset-8">Sākumlapa</button>
                <button onClick={() => scrollToSection('about')} className="text-white my-4 text-2xl hover:underline hover:underline-offset-8">Par mums</button>
                <button onClick={() => scrollToSection('info')} className="text-white my-4 text-2xl hover:underline hover:underline-offset-8">Kontakti</button>
                <a href="tel:+37112345678" className="text-white my-4 text-2xl hover:underline hover:underline-offset-8">Zvanīt</a>
                <div className="absolute bottom-0 right-0 mb-4 mr-4 text-white text-sm">
                    <a
                        href="tel:+37164161016"
                        className="flex items-center hover:text-gray-600 transition duration-300 ease-in-out"
                    >
                        <FaPhone className="mr-2" />
                        +37164161016
                    </a>
                    <a
                        href="mailto:glendeloka@inbox.lv"
                        className="flex items-center hover:text-gray-600 transition duration-300 ease-in-out"
                    >
                        <FaEnvelope className="mr-2" />
                        glendeloka@inbox.lv
                    </a>
                </div>
            </nav>
        </header>
    );
};

export default Header;
