// src/App.js
import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import About from './components/About';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import Info from './components/Info';
import Wave1 from "./components/items/Wave1";
import Wave2 from "./components/items/Wave2";
import Wave3 from "./components/items/Wave3";

function App() {
    return (
        <div className="App">
            <Header />
            <Hero />
            <Services />
            <Wave3 />
            <About />
            <Wave1 />
            <Testimonials />
            <Wave2 />
            <Info />
            <Wave1 />
            <Footer />
        </div>
    );
}

export default App;
